<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="">
    <div class="">
      <div class="" v-bind:class="{'open-filter-box': filterBoxActive}">
        <div class="add-property-title  align-items-baseline">
          <div> {{ $t('admin-dashboard-title') }}</div>
        </div>
        <div>
          <div class="d-flex  "
               v-bind:class="{'open-filter-box': filterBoxActive}"
          >
            <div class="  ">
              {{ $t('order-stats') }}
            </div>
            <div class=" ms-auto  sort-filter d-none d-md-flex">

              <div class="d-flex align-items-center sort-by-button me-3">
                <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }}</div>
                <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
                  <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="sort-filter-modal ">
                <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{
                    option.title
                  }}
                </div>
              </div>
            </div>

          </div>
          <div class="property-dashboard-graph"></div>
        </div>

        <div class="stats-row d-flex">
          <div class=" stats-grid-item ">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-all-products') }}</div>
              <div class="count-number"> {{ statistics.products_count }}</div>
            </div>

          </div>
          <div class=" stats-grid-item">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-products-views') }}</div>
              <div class="count-number"> {{ statistics.views_count }}</div>
            </div>

          </div>
          <div class=" stats-grid-item">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-profile-visitors') }}</div>
              <div class="count-number"> {{ statistics.profile_visitors_count }}</div>
            </div>

          </div>
          <div class=" stats-grid-item">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-total-orders') }}</div>
              <div class="count-number"> {{ statistics.total_orders_count }}</div>
            </div>

          </div>
          <div class=" stats-grid-item">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-new-orders') }}</div>
              <div class="count-number"> {{ statistics.new_orders_count }}</div>
            </div>

          </div>
        </div>

        <div class=" orders-wrapper ">
          <div class="ms-2 d-flex align-items-center ">
            <div>
              <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="4.5" y1="0.5" x2="14.5" y2="0.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="0.5" x2="1.5" y2="0.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="4.5" y1="4.5" x2="14.5" y2="4.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="4.5" x2="1.5" y2="4.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="4.5" y1="8.5" x2="14.5" y2="8.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="8.5" x2="1.5" y2="8.5" stroke="#FF7A00" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="stats-title"> {{ $t('vendor-last-orders') }}</div>
          </div>
          <div class=" labels-order  d-none d-md-flex ">
            <div class="order-id col-1"> #</div>
            <div class="client-name col-2">{{ $t('vendor-client-name') }}</div>
            <div class="client-phone col-2">{{ $t('vendor-client-phone') }}</div>
            <div class="order-status col-2 ">
              {{ $t('vendor-order-status') }}
            </div>
            <div class="order-amount col-2"> {{ $t('vendor-order-amount') }}</div>
            <div class="date col-2"> {{ $t('vendor-order-date') }}</div>
          </div>


          <div class="inquiry-item" v-for="order in orders">

            <AdminVendorListItem
                :date="order.date"
                :orderstatus="order.status"
                :orderid="order.order_id"
                :name="order.name"
                :phone="order.phone"
                :orderamount="order.order_amount"
            />
          </div>
        </div>

        <div class="row views-sales-stats" v-if="false">
          <div class="inquiry-item col-md-6">
            <DashboardVendorStats
                :type="'views'"
            />
          </div>
          <div class="inquiry-item col-md-6">
            <DashboardVendorStats
                :type="'inquiries'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
