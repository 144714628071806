import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import DashboardVendorStatsListItem
    from "@/views/AdminDashboard/AdminDashboardVendor/DashboardVendorStatsListItem/DashboardVendorStatsListItem/";

export default {
    name: 'DashboardPropertyStats',
    props: {
        type: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        orderamount: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        orderstatus: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        DashboardVendorStatsListItem

    },
    mounted() {
    },
    data() {
        return {
            products: [
                {
                    adnumber: 1,
                    views: 2321,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },
                {
                    adnumber: 2,
                    views: 3421,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },
                {
                    adnumber: 3,
                    views: 2571,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },
                {
                    adnumber: 4,
                    views: 666,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },
                {
                    adnumber: 5,
                    views: 321,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },
                {
                    adnumber: 6,
                    views: 444,
                    category: 'Телевизори',
                    date: '21.11.2022',
                    product_id: '222324',
                    title: 'Телевизор 4К JVC 32 инча Андроид',
                    visible: 'Да',
                    price: '209,90',

                },


            ],
            translation: {
                property: 'Имот',
                product: 'Продукт',
                views: 'Преглеждания',
                sales: 'Продажби',
                inquiries: 'Заявки',
                best_sell_products: 'Най-продавани продукти',
                most_viewed_products: 'Най-преглеждани продукти',
            }
        }
    }
}