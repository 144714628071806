import Button from "@/components/Common/Buttons/Button/Button";
import Input from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import DashboardVendorStats
    from "@/views/AdminDashboard/AdminDashboardVendor/DashboardVendorStats/DashboardVendorStats/";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import Pagination from "@/components/Components/Pagination/Pagination/";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import AdminVendorListItem from "@/components/Components/AdminVendorListItem/AdminVendorListItem/";


export default {
    name: 'AdminDashboardVendor',
    components: {
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        DashboardVendorStats,
        TextArea,
        Dropdown,
        Input,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination,
        AdminVendorListItem
    },
    data() {
        return {
            filterBoxActive: false,
            activeInactiveSwitch: 'active',
            orders: [
                {
                    status: 'printed',
                    date: '21.11.2022',
                    order_id: '222324',
                    name: 'Жозефина Илиева',
                    phone: '0888 888 888',
                    order_amount: '209,90',

                },
                {
                    status: 'done',
                    date: '21.11.2022',
                    order_id: '4343',
                    name: 'Дорис Стефанова',
                    phone: '0899 223 444',
                    order_amount: '1349,90',

                },
                {
                    status: 'printed',
                    date: '21.11.2022',
                    order_id: '4345',
                    name: 'Теодора Набожникова',
                    phone: '0888 888 888',
                    order_amount: '209,90',

                },
                {
                    status: 'printed',
                    date: '21.11.2022',
                    order_id: '222324',
                    name: 'Димитричка Ивайлова',
                    phone: '0885 855 588',
                    order_amount: '5209,90',

                },
                {
                    status: 'printed',
                    date: '21.11.2022',
                    order_id: '222324',
                    name: 'Иванка Илиева',
                    phone: '0883 383 383',
                    order_amount: '456,90',

                },
                {
                    status: 'done',
                    date: '21.11.2022',
                    order_id: '222324',
                    name: 'Данчо Колев',
                    phone: '0888 888 888',
                    order_amount: '209,90',

                },
                {
                    status: 'done',
                    date: '21.11.2022',
                    order_id: '222324',
                    name: 'Димо Маринов',
                    phone: '0888 888 888',
                    order_amount: '209,90',

                },
            ],
            statistics: {
                products_count: 242,
                views_count: 1043,
                profile_visitors_count: 24,
                total_orders_count: 24,
                new_orders_count: 24,
            },
            sortingOptions: [
                {
                    title: this.$t('last-week'),
                    value: 'last_week',
                },
                {
                    title: this.$t('last-month'),
                    value: 'last_month',
                },
                {
                    title: this.$t('last-quarter'),
                    value: 'last_quarter',
                },
                {
                    title: this.$t('last-year'),
                    value: 'last_year',
                },
            ],
            selectedOption: {
                title: this.$t('select-period'),
                value: '',
            },


        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        }

    },
    async mounted() {
        if (!this.$store.state.user.authenticated) {
            await this.$store.dispatch("utils/openLoginModal");
            this.$router.push({name: 'Index'});
        }

        if (!this.$store.state.user.info.type === 'vendor') {
            this.$router.push({name: 'AgentDashboard'});
        }
    },
}
