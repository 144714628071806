<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="property-stats-wrapper">
      <div class="d-flex align-items-center" v-if="type === 'views'">
        <div>
          <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.61629 10.0035C6.47176 9.99733 5.49744 9.72691 4.57594 9.25983C3.15554 8.54077 1.95819 7.50828 0.895825 6.29755C0.643441 6.01485 0.402795 5.70756 0.168019 5.40027C-0.0550183 5.11141 -0.0550183 4.89016 0.16215 4.60131C1.22451 3.20007 2.45122 1.99549 3.93618 1.09205C4.82832 0.551222 5.77917 0.164036 6.81218 0.0411201C7.95085 -0.0940877 9.03669 0.108724 10.0814 0.581951C11.4314 1.19039 12.5877 2.09382 13.6148 3.17548C14.0433 3.63027 14.4365 4.12194 14.8298 4.60131C15.0587 4.88402 15.0528 5.11141 14.8357 5.40027C13.7792 6.79536 12.5525 7.99994 11.0734 8.90338C10.1812 9.45035 9.23038 9.83139 8.19736 9.96046C7.97432 9.99118 7.73955 9.99733 7.61629 10.0035ZM1.10712 4.98235C1.59428 5.58464 2.08731 6.13162 2.62143 6.62328C3.54879 7.47755 4.55833 8.20275 5.73808 8.63911C6.46002 8.90338 7.20543 9.02629 7.96846 8.9464C8.96625 8.84192 9.87014 8.45473 10.7271 7.92005C11.8188 7.23172 12.7462 6.35287 13.5913 5.36339C13.6911 5.24662 13.785 5.1237 13.8789 5.00693C13.8496 4.96391 13.8261 4.93318 13.8085 4.9086C13.6324 4.70579 13.4622 4.49683 13.2803 4.29402C12.259 3.15705 11.1145 2.18601 9.74102 1.55913C9.14234 1.28257 8.51431 1.0982 7.85694 1.05518C6.75936 0.975283 5.74395 1.28872 4.78137 1.81111C3.87161 2.28434 3.07337 2.91736 2.34557 3.64256C1.92297 4.06662 1.52385 4.52756 1.10712 4.98235Z"
                fill="#FF7A00"/>
            <path
                d="M10.463 5.00697C10.463 6.72165 9.1306 8.11061 7.49303 8.10446C5.8496 8.09832 4.52899 6.70936 4.53486 4.99468C4.54659 3.27386 5.86134 1.90334 7.4989 1.90334C9.14234 1.89719 10.4688 3.28615 10.463 5.00697ZM7.50477 2.92969C6.4248 2.9174 5.54439 3.83312 5.52091 4.98239C5.49744 6.11322 6.38959 7.05353 7.49303 7.06582C8.573 7.07811 9.45928 6.16239 9.47689 5.01312C9.4945 3.88229 8.60822 2.94198 7.50477 2.92969Z"
                fill="#FF7A00"/>
          </svg>

        </div>
        <div class="stats-title">
          {{ translation.most_viewed_products }}
        </div>
      </div>
      <div class="d-flex align-items-center " v-if="type === 'inquiries'">
        <div>
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="4.5" width="11" height="11" rx="1.5" stroke="#FF7A00"/>
            <path d="M9 7V3.57143C9 2.15127 7.84873 1 6.42857 1H5.57143C4.15127 1 3 2.15127 3 3.57143V7"
                  stroke="#FF7A00" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="stats-title">
          {{ translation.best_sell_products }}
        </div>
      </div>
      <div class="row top-table-row g-0 d-none d-md-flex">
        <div class="col-1"> #</div>
        <div class="col-2"> №</div>
        <div class="col-7"> {{ translation.product }}</div>
        <div class="col-2" v-if="type === 'views'"> {{ translation.views }}</div>
        <div class="col-2  text-center" v-if="type === 'inquiries'"> {{ translation.sales }}</div>
      </div>
      <div class="row ranking-table-row" v-for="product in products">
        <DashboardVendorStatsListItem
            :adnumber="product.adnumber"
            :title="product.title"
            :productid="product.product_id"
            :views="product.views"
            :date="product.date"
        />
      </div>
    </div>


  </div>
</template>

